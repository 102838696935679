/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { styled } from "@hiyllo/ux/styled";
import './App.css';

const Container = styled<"div", {$show:boolean}>("div", ({$theme, $show}) => ({
  opacity: $show ? 1 : 0,
  transition: "opacity 0.3s",
  // background: $theme.background1,
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "hiyllo, sans-serif",
  gap: 25,
  color: $theme.foreground
}));

const AppContainer = styled("div", ({$theme}) => ({
  borderLeft: "2.5px white solid",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: 25,
  gap: 20,
  color: "#fff",
  width: 400,
  cursor: "pointer",
  userSelect: "none",
  textDecoration: "none",
}));

const AppName = styled("div", {
  fontSize: 30,
  fontWeight: "bold",
});

const AppDescription = styled("div", {
});

const AppLogo = styled("img", {
  height:50, width:50, objectFit:"contain", objectPosition:"center",
  flexShrink: 0
})

function App() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <Container $show={show}>
      <img
        src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
        style={{height:50}}
      />
      <div>We&apos;re Hiyllo, a tech company based in Princeton, NJ. We make products:</div>

      <div>For Companies</div>
      <a href="https://www.hiyllo.work">
        <AppContainer>
          <AppLogo src="https://cdn.hiyllo.net/logo/work/icon-gradient.png" />
          <div>
            <AppName>Hiyllo Work</AppName>
            <AppDescription>
              Chat, Project Management, Docs, File Storage, Video Meetings, and Email. Everything your team needs in one place.
            </AppDescription>
          </div>
        </AppContainer>
      </a>
      <AppContainer>
        <AppLogo src="https://cdn.hiyllo.net/logo/support/color-icon.png" />
        <div>
          <AppName>Hiyllo Support</AppName>
          <AppDescription>
            Coming Soon. A down-to-earth support platform built from the ground up, with a focus on simplicity and usability.
          </AppDescription>
        </div>
      </AppContainer>  
      <div>For Individuals</div>
      <a href="https://solo.hiyllo.io">
        <AppContainer>
          <AppLogo src="https://solo.hiyllo.io/favicon.png" />
          <div>
            <AppName>Hiyllo Solo</AppName>
            <AppDescription>
              Time & Task Management for busy people on the move. Syncs your work and personal life, so you can balance both easier.
            </AppDescription>
          </div>
        </AppContainer>   
      </a>
      <div style={{fontSize: 12.5, opacity: 0.5, userSelect:"none"}}>
        &copy; Hiyllo Inc, 2024. All rights reserved. <span style={{userSelect:"text", fontWeight:'bold'}}>support@hiyllo.io</span>
      </div>   
    </Container>
  );
}

export default App;
